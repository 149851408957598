import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FooterBox from '../components/share/footerBox'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "brandManagement/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    book: file(relativePath: { eq: "brandManagement/book.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    megaphone: file(relativePath: { eq: "brandManagement/megaphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    people: file(relativePath: { eq: "brandManagement/people.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    stats: file(relativePath: { eq: "brandManagement/stats.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class BrandsPage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                opacity="0.6"
                style={{ height: '600px' }}
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'code-branch']}
                    style={{ width: '25px', height: '25px', marginBottom: 10 }}
                  />
                  <BannerH6 style={{ marginBottom: 20, textAlign: 'start' }}>
                    Built for Brands -- <strong>From 3 offices to 3,000</strong>
                  </BannerH6>
                  <HeaderTitle>Tools for Brand Groups</HeaderTitle>

                  <HeaderBody>
                    Introduce complete oversight and control over your entire
                    national group. Insights into the combined marketing spend
                    of your entire network and control over the country-wide
                    appearance of your brand.
                  </HeaderBody>

                  <TypeFormButton text="MORE INFO" />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  From <strong>a single office</strong> to{' '}
                  <strong>national brands,</strong> save confusion and market
                  more efficiently with brand control.
                </BannerH4>
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.people.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Centralised brand control with decentralized usage of
                          brand assets.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        You won’t need to seek approval from head office to
                        start building property marketing. All the templates we
                        build come with brand consistency and rules baked in.
                      </Paragraph>
                      <Paragraph>
                        Every asset provided by a brand, and the ability to
                        store your own office specific image libraries make your
                        office better equipped to take controlled branding and
                        inject your own personality.
                      </Paragraph>
                      <Paragraph>
                        Keeping you updated with the latest designs, using
                        data-driven decision making to expand the template
                        offering around the items you need the most and use the
                        most often.
                      </Paragraph>
                      <Paragraph>
                        You’ll be able to fit in where it counts, and stand our
                        where it matters.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                height="700px"
                opacity="0.4"
                background={data.book.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    reverse
                    descWidth="45%"
                    title={
                      <strong>
                        Your unique brand and asset library, custom tailored to
                        your office.
                      </strong>
                    }
                    content={
                      <Fragment>
                        <Paragraph>
                          We take control of all your brand assets, from logos
                          and typefaces to the photography that best represents
                          your brand in the marketplace. Combining it all in one
                          place where it’s easy to access and easier to use.
                        </Paragraph>
                        <Paragraph>
                          You’ll never have to worry about being off-brand or
                          outside the scope of a marketing campaign with the
                          un-paralleled levels of control Realhub provides.
                        </Paragraph>
                        <Paragraph>
                          Having the brand specifics safely locked away and
                          keeping your marketing versatile and maleable to every
                          possible requirement will help your office, no matter
                          the user market themselves and your brand efficiently.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper>
                <IntroRow
                  image={data.stats.childImageSharp.fluid}
                  title="Unlock unique brand level insights on your business."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Control and monitor the spending, across an entire
                          national group from one place.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        A million and one moving parts, brand groups of all
                        sizes have one common problem, how do you keep track of
                        what all your offices are doing, where they are spending
                        their money and how they are performing in one place.
                      </Paragraph>
                      <Paragraph>
                        As a brand manager with Realhub you’ll be able to make
                        more informed decisions, improve your negotiating power
                        with suppliers and quickly react to issues with team
                        performance as they arise.
                      </Paragraph>

                      <Paragraph>
                        <strong>
                          Migration specialists, for when it’s time to move{' '}
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        We’ve acheived what no-one else in the market has ever
                        had to. The movement of massive office groups, with
                        minimal friction. Brands that migrate through to our
                        platform experience for themselves the quality and speed
                        with which we act, and support their network.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Get <strong>every real estate service</strong> in{' '}
                  <strong>one place</strong>, and save hours communicating and
                  tracking entire campaigns.
                </BannerH4>

                <TypeFormButton />
              </ContentWrapper>
              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>
              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>
              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default BrandsPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
